<template>
  <v-card class="mx-auto my-1"  :loading="loading">
     <v-card-actions>
      <v-btn @click="$router.go(-1)" small text>
        <v-icon>mdi-arrow-left</v-icon> Back
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn class="success" @click="$router.push({name:'Poultry Farmers'})"  text>
        <v-icon>mdi-table</v-icon>Farmers
      </v-btn>
    </v-card-actions>
    <v-img
      class="white--text align-end"
      height="200px"
       :src="imgurl"
    >
      <v-card-title> </v-card-title>
    </v-img>

    <v-card-subtitle class="pb-0 text-h3 font-weight-black ">
      {{ title.commodity }} {{ title.desc }}
    </v-card-subtitle>

    <v-card-text class="text--primary">
      <v-card  class="my-1">
        <v-card-title>Production </v-card-title>
        <v-card-text>
          <div class="text-h2 font-weight-black text-center">{{totalbyMonth}}</div>
          <div class="text-caption text-center">Available for {{monthProd}}</div>
        </v-card-text>
        
      </v-card>
      <v-card class="my-1">
        <v-card-title>Suggested Price</v-card-title>
        <v-card-text class="text-h2 font-weight-black text-center">{{farmgate.farmgatebegin}}-{{farmgate.farmgateend}}</v-card-text>
      </v-card>
        <v-card class="my-1">
        <v-card-title>Top Municipality/ City</v-card-title>
        <v-card-subtitle class="my-1">Highest Production for the Month</v-card-subtitle>
        <v-card-text>
         <v-data-table
           dense
            :headers="headerstopmunicipal"
            :items="topMunicipal"
            :items-per-page="5"
            sort-by="total"
            sort-desc="true"
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-card-text>

   
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LivestockDetails",
  data() {
    return {
      title: [],
      monthProd: "",
      totalbyMonth:"",
      farmgate: [],
      topMunicipal: [],
      loading:false,
      imgurl:'',
      headerstopmunicipal:[
        { text: "Municipal", value: "municipal" },
        { text: "Available", value: "total" },
      ]
    };
  },

  computed: {
    ...mapGetters([
      "poultry/getSelected",
      "poultry/getMonthProd",
      "poultry/getFarmGate",
      "poultry/getTopMunicipal",
      "poultry/getTotalbyMonth"
    ]),
    ...mapActions([
      "poultry/getDetails"
    ])

  },

  mounted() {
     this.getDetails();
  },

  methods: {
    async getDetails() {
      this.loading=true
      await this["poultry/getDetails"]
      this.title = await this["poultry/getSelected"];
      this.topMunicipal = await this["poultry/getTopMunicipal"];
      this.monthProd = await this["poultry/getMonthProd"];
      this.totalbyMonth= await this["poultry/getTotalbyMonth"]
      this.farmgate = await this["poultry/getFarmGate"];
      this.imgurl=await this.farmgate.imgurl
      this.loading=false
    },
  },
};
</script>
